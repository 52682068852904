import { messages as utilMessages } from '@sdir/utilities/lib/localization';

/* eslint-disable max-len */
const messages = {
  nb: {
    ...utilMessages.no,
    'language': 'Norsk',
    'birthnumber': 'Fødselsdato',
    'certificate.number': 'Sertifikatnummer',
    'certificate.number.length': 'Sertifikatnummer må være mellom 4-10 nummer',
    'certificate.number.toobig': 'Sertifikatnummeret er for stort',
    'certificate.number.only.digits': 'Sertifikatnummer kan kun være tall',
    'certificate.notfound': 'Sertifikat ikke funnet',
    'required.field': 'Obligatorisk felt',
    'search': 'Søk',
    'certificate.search.header': 'Søk etter sertifikat',
    'certificate.overview.header.valid': 'Sertifikat er gyldig',
    'certificate.overview.header.invalid': 'Sertifikat er ikke gyldig',
    'certificate.status.Valid': 'Sertifikat er gyldig',
    'certificate.status.Cancelled': 'Sertifikat er kansellert',
    'certificate.status.Expired': 'Sertifikat er utgått',
    'certificate.status.Inactive': 'Sertifikat er inaktivt',
    'certificate.overview.number': 'Sertifikatnummer',
    'certificate.overview.cert.name': 'Sertifikat',
    'certificate.overview.type': 'Sertifikattype',
    'certificate.overview.name': 'Navn',
    'certificate.overview.validfrom.date': 'Gyldig fra',
    'certificate.overview.issued.date': 'Utstedt dato',
    'certificate.overview.expiry.date': 'Utløpsdato',
    'certificate.overview.code': 'Sertifikatkode',
    'header.language.english': 'Engelsk',
    'header.language.norwegian': 'Norsk',
    'home.page.title': 'Verification Service',
    'userbar.sdirlink': 'Gå til Sjøfartsdirektoratet (sdir.no)',
    'footer.tagline': '- Den foretrukne maritime administrasjonen',
    'footer.social.header': 'Følg oss',
    'footer.social.facebook': 'Besøk oss på Facebook',
    'footer.social.linkedin': 'Besøk oss på LinkedIn',
    'footer.social.instagram': 'Besøk oss på Instagram',
  },
  en: {
    ...utilMessages.en,
    'language': 'English',
    'birthnumber': 'Date of birth',
    'certificate.number': ' Certificate nr.',
    'certificate.number.length': 'Certificate nr. must be between 4-10 numbers',
    'certificate.number.toobig': 'Certificate nr. is too large',
    'certificate.number.only.digits': 'Certificate nr. can only contain numbers',
    'certificate.notfound': 'Certificate not found',
    'required.field': 'Required field',
    'search': 'Search',
    'certificate.search.header': 'Search for certificate',
    'certificate.overview.header.valid': 'Certificate is valid',
    'certificate.overview.header.invalid': 'Certificate is not valid',
    'certificate.status.Valid': 'Certificate is valid',
    'certificate.status.Cancelled': 'Certificate is cancelled',
    'certificate.status.Expired': 'Certificate is expired',
    'certificate.status.Inactive': 'Certificate is inactive',
    'certificate.overview.number': 'Certificate number',
    'certificate.overview.cert.name': 'Certificate name',
    'certificate.overview.type': 'Certificate type',
    'certificate.overview.name': 'Name',
    'certificate.overview.validfrom.date': 'Valid from',
    'certificate.overview.issued.date': 'Issued date',
    'certificate.overview.expiry.date': 'Expiry date',
    'certificate.overview.code': 'Certificate code',
    'header.language.english': 'English',
    'header.language.norwegian': 'Norwegian',
    'home.page.title': 'Verification Service',
    'userbar.sdirlink': 'Go to Sjøfartsdirektoratet (sdir.no)',
    'footer.tagline': '- The preferred maritime administration',
    'footer.social.header': 'Follow us',
    'footer.social.facebook': 'Visit us on Facebook',
    'footer.social.linkedin': 'Visit us on LinkedIn',
    'footer.social.instagram': 'Visit us on Instagram',
  },
};

export default messages;

import { SdirButton } from '@sdir/sds';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import moment from 'moment';
import styled from 'styled-components';
import { ErrorMessage } from '@sdir/utilities/lib/components';
import { FormDate, FormInput } from '@sdir/blueprint.aps/lib/components';
import CertificateView, { CertificateViewProps } from '../../Molecules/CertificateView';
import { personalCertificateVerifyApi } from '../../../httpclient';
import { ConvertCertificate } from '../../../helpers/ConvertCertificate';

type InitialValues = {
  dateOfBirth: string;
  certNr: string;
};

const maxDate = new Date(2500, 11, 31);

const SearchForm: React.FC = () => {
  const intl = useIntl();
  const [certificate, setCertificate] = useState<null | CertificateViewProps>();
  const [searchError, setSearchError] = useState<any>();

  const initialValues: InitialValues = {
    dateOfBirth: '',
    certNr: '',
  };

  const handleSubmit = useCallback(
    (values: InitialValues) => {
      const shortDateOfBirth = values?.dateOfBirth
        ? moment(values?.dateOfBirth).format('YYYY-MM-DD')
        : '';
      personalCertificateVerifyApi
        .v2PersonalcertificateverifyVerifybynumberandidentityv2DataPost({
          certificateNumber: +values.certNr,
          dateofBirth: shortDateOfBirth,
        })
        .then((response) => {
          if (response.status === 200) {
            setCertificate(ConvertCertificate(response.data));
            setSearchError(undefined);
          }
        })
        .catch((error) => {
          const { status } = error?.response || {};
          if (status >= 400 && status < 500) {
            setSearchError(intl.formatMessage({ id: 'certificate.notfound' }));
          } else {
            setSearchError(error);
          }
        });
    },
    [intl]
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      dateOfBirth: Yup.string().required(intl.formatMessage({ id: 'required.field' })),
      certNr: Yup.string()
        .matches(/^[0-9]+$/, intl.formatMessage({ id: 'certificate.number.only.digits' }))
        .min(4, intl.formatMessage({ id: 'certificate.number.length' }))
        .max(10, intl.formatMessage({ id: 'certificate.number.length' }))
        .test('int32', intl.formatMessage({ id: 'certificate.number.toobig' }), (v?: string) => {
          if (!v) return true;
          const nr = parseInt(v, 10);
          if (typeof nr === 'number') {
            return nr <= 2147483647;
          }
          return true;
        })
        .required(intl.formatMessage({ id: 'required.field' })),
    });
  }, [intl]);

  return (
    <>
      <h1>{intl.formatMessage({ id: 'certificate.search.header' })}</h1>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {() => (
          <StyledForm>
            <Field
              label={intl.formatMessage({ id: 'birthnumber' })}
              name="dateOfBirth"
              component={FormDate}
              locale={intl.locale || 'no'}
              maxDate={maxDate}
              showYearDropdown
              showMonthDropdown
            />
            <Field
              label={intl.formatMessage({ id: 'certificate.number' })}
              name="certNr"
              component={FormInput}
            />
            <StyledSdirButton
              htmlType="submit"
              type="primary"
              text={intl.formatMessage({ id: 'search' })}
            />
            {searchError && <ErrorMessage error={searchError} />}
          </StyledForm>
        )}
      </Formik>
      {certificate && !searchError && <CertificateView {...certificate} />}
    </>
  );
};

const StyledForm = styled(Form)`
  display: flex;
  grid-row-gap: 1em;
  flex-direction: column;
  max-width: 400px;
`;

const StyledSdirButton = styled(SdirButton)`
  width: fit-content;
`;

export default SearchForm;

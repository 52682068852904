import { HttpClient } from '@sdir/httpclient/lib/HttpClient';
import { PersonalCertificateVerifyApi } from '@sdir/httpclient/lib/clients/aps/personalcertificate';
import { DEFAULT_LANG, getCurrentLanguageOutsideProvider } from '../helpers/languageHelpers';

const clientId = 'verify';
const authority = process.env.REACT_APP_EXTERNALAUTHURL || '';
const baseUrl = process.env.REACT_APP_API_URL;
const httpClient = new HttpClient(baseUrl);

httpClient.addAPIMSubscriptionKeyHeader(process.env.REACT_APP_SUBSCRIPTIONKEY);
httpClient.addOICDAuthorizationHeader(authority, clientId);
httpClient.addRequestMiddleware((req) => {
  const currentLang = getCurrentLanguageOutsideProvider();
  req.headers = {
    ...req.headers,
    'Accept-Language': req.headers?.['Accept-Language'] || currentLang || DEFAULT_LANG,
  };
  return req;
});

export const personalCertificateVerifyApi = new PersonalCertificateVerifyApi(
  undefined,
  `${baseUrl}/aps-personalcertificate-internal`,
  httpClient.client
);
